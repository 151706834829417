import Button from 'Components/Button'
import Input from 'Components/Input'
import Heading from 'Components/Heading'
import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { MANAGE_BOOKING } from 'Routes/routes'
import { useDispatch, useSelector } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import Spinner from 'Components/Spinner'
import moment from 'moment'
import CSVModal from 'Components/Modals/CSVModal'
import EmptyState from 'Components/EmptyState'
import { ShareIcon, ClockIcon } from '@heroicons/react/outline'
import store from 'store'
import ManualBookingView from 'Components/Bookings/ManualBooking/ManualBookingView'
import AppointoPagination from 'Components/AppointoPagination'
import { ItemsPerPage } from 'Redux/genericReducers'
import RangeDatePicker from 'Components/Modals/RangeDatePicker'
import useDebounce from 'Hooks/debounce'
import { none } from 'ramda'

const headers = [
  'Service Name',
  'Appointment Time',
  'Duration',
  'Team Member',
  'Customer Info',
  '',
]

const Booking = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [bookings, setBookings] = useState([])
  const bookingsFetching = useSelector((state) => state.booking.listFetching)
  const listDataOffset = useSelector((state) => state.booking.listDataOffset)
  const bookingsList = useSelector((state) => state.booking.list)
  const [showCsvModal, setShowCsvModal] = useState(false)
  const [showManualModal, setShowManualModal] = useState(false)
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [selected, setSelected] = useState(0)
  const [bookingStatus, setBookingStatus] = useState('upcoming')
  const currentTab = useSelector((state) => state.navigation.currentTab)
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })
  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }
  const [selectedDate, setSelectedDate] = useState()
  const [showDatePicker, setShowDatePicker] = useState(false)
  const userData = store.get('userData')
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [queryValue, setQueryValue] = useState('')
  const debouncedQueryValue = useDebounce(queryValue, 300)

  useEffect(() => {
    dispatch(BookingActions.bookingsListRequest({ status: 'upcoming' }))
  }, [])

  const bookNow = (booking) => () => {
    window.open(
      `${booking.booking_link}&fromAdmin=true&bookingConflict=true`,
      '_blank'
    )
  }

  const removeConflict = (c) => () => {
    setIsLoading(true)
    dispatch(
      BookingActions.deleteConflict(c.id, () => {
        setIsLoading(false)
        dispatch(BookingActions.conflictsRequest())
      })
    )
  }
  const onChangeDate = (selectedDates) => {
    // setAvailData(props.appointmentId, selectedDates, true)
    setSelectedDate(selectedDates)
  }

  const shareLink = (b) => () => {
    let text = `${window.location.origin}${MANAGE_BOOKING}/${
      b.id
    }?cancelled=${!!b.cancelled}`
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Copied')
        alert('Copied the text: ' + text)
      })
      .catch((err) => {
        console.log('Something went wrong', err)
      })
  }

  useEffect(() => {
    if (bookingsList?.length > 0) {
      let s = bookingsList.map((b, i) => {
        return [
          b.product_name,
          moment(b.selected_time).format('llll'),
          {
            value: (
              <div className="flex gap-2 items-center ">
                <ClockIcon className="w-5" />
                <p>{b.duration} mins</p>
              </div>
            ),
            key: `duration${i}`,
          },
          b.team_member?.name,
          {
            value:
              !!b.customers &&
              Object.entries(
                b.customers.reduce(
                  (acc, { name }) => ((acc[name] = (acc[name] || 0) + 1), acc),
                  {}
                )
              ).map(([name, count]) => (
                <p key={name}>{`${name}${count > 1 ? ` x ${count}` : ''}`}</p>
              )),
            key: `customers${i}`,
          },
          {
            value: (
              <div className="flex gap-2.5">
                {/* <Button
                    type="defaultGray"
                    title={<ShareIcon className="w-5" color="#5B93FF" />}
                    onClick={shareLink(b)}
                  /> */}
                <Button
                  type="default"
                  title="Manage"
                  onClick={() => {
                    history.push(
                      `${MANAGE_BOOKING}/${b.id}?cancelled=${!!b.cancelled}`
                    )
                  }}
                />
              </div>
            ),
            type: 'Button',
          },
        ]
      })
      setBookings(s)
    }
  }, [bookingsList, currentTab])

  useEffect(() => {
    if (queryValue.length >= 3) {
      dispatch(
        BookingActions.bookingsListRequest({
          search_term: queryValue,
          status: currentTab,
          startDate: startDate,
          endDate: endDate,
        }),
        0
      )
    }
    if (queryValue.length == 0) {
      dispatch(
        BookingActions.bookingsListRequest({
          startDate: startDate,
          endDate: endDate,
          status: currentTab,
        }),
        0
      )
    }
  }, [debouncedQueryValue])

  useEffect(() => {
    if (currentTab === 'cancelled') {
      dispatch(BookingActions.bookingsCancelListRequest({}))
    } else if (currentTab === 'upcoming') {
      dispatch(BookingActions.bookingsListRequest({ status: currentTab }))
    } else if (currentTab === 'past') {
      dispatch(BookingActions.bookingsListRequest({ status: currentTab }))
    }
    setBookingStatus(currentTab)
  }, [currentTab])

  const exportCSV = () => {
    setShowCsvModal(true)
  }

  const manualBooking = () => {
    setShowManualModal(true)
  }

  const onPrevious = () => {
    dispatch(
      BookingActions.bookingsListRequest(
        { status: bookingStatus },
        listDataOffset - ItemsPerPage
      )
    )
  }

  const onNext = () => {
    dispatch(
      BookingActions.bookingsListRequest(
        { status: bookingStatus },
        listDataOffset + ItemsPerPage
      )
    )
  }

  return (
    <div>
      {showManualModal ? (
        <ManualBookingView
          showModal={showManualModal}
          handleClose={(success) => {
            setShowManualModal(false)
            if (success) {
              setShowSuccessToast(true)
              setSelected(0)
              dispatch(
                BookingActions.bookingsListRequest({ status: 'upcoming' })
              )
            }
          }}
          setShowManualModal={setShowManualModal}
          user={props.user}
          shop={props.shop}
        />
      ) : (
        <>
          <Heading
            title="Bookings"
            rightSection={
              <div className="flex gap-3 items-center">
                <Button
                  title="Export Bookings CSV"
                  onClick={exportCSV}
                  type="default"
                />
                <Button
                  type="primary"
                  title="+Manual Booking"
                  onClick={manualBooking}
                />
              </div>
            }
          />
          <div className="bg-white p-5 rounded-tr-md rounded-tl-md">
            <div className="grid grid-cols-12 mb-4 justify-between gap-4 items-end">
              <div className="col-span-4 sm:col-span-6">
                <Input
                  label="Filter Bookings"
                  value={queryValue}
                  placeholder="Filter customers by name or email"
                  onChange={(value) => setQueryValue(value)}
                />
              </div>
              <div className="col-span-4 sm:col-span-6 relative">
                <Input
                  name="start"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Set Date Range"
                  label="Set Date Range"
                  type="text"
                  value={
                    endDate && startDate
                      ? `${moment(startDate).format('YYYY-MM-DD')} - ${moment(
                          endDate
                        ).format('YYYY-MM-DD')}`
                      : ''
                  }
                  onClick={() => {
                    setShowDatePicker(!showDatePicker)
                  }}
                />
                {showDatePicker && (
                  <div className="absolute z-50">
                    <RangeDatePicker
                      selected={startDate}
                      onChange={onChange}
                      startDate={startDate}
                      endDate={endDate}
                      selectsRange
                      inline
                      nextMonthButtonLabel=">"
                      previousMonthButtonLabel="< "
                      submit={true}
                      onSubmit={() => {
                        setStartDate(startDate)
                        setEndDate(endDate)
                        setShowDatePicker(!showDatePicker)
                        dispatch(
                          BookingActions.bookingsListRequest({
                            search_term: queryValue,
                            start_date: startDate,
                            end_date: endDate,
                            status: currentTab,
                          }),
                          0
                        )
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="my-6">
            {bookingsFetching ? (
              <Spinner />
            ) : bookingsList.length === 0 ? (
              <EmptyState
                header="NO Bookings"
                subHeader="Please book a time to show bookings here"
              />
            ) : (
              <>
                <Table headers={headers} data={bookings} />
                <AppointoPagination
                  offset={listDataOffset}
                  onPrevious={onPrevious}
                  list={bookings}
                  onNext={onNext}
                />
              </>
            )}
          </div>

          {showCsvModal && (
            <CSVModal
              showModal={showCsvModal}
              setShowModal={setShowCsvModal}
              shop={props.shop}
            />
          )}
        </>
      )}
    </div>
  )
}

export default Booking
